import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* subApplicationTimeLine(data) {
  try {
    const result = yield call(allEndPoints.subApplicationTimeLine, data.data);
    console.log(result);
    yield put(actions.recieveSubApplicationTimeLine({ ...result }.data.data));
  } catch (error) {
    yield put(actions.failSubApplicationTimeLine);
  }
}

function* watchSubApplicationTimeLine() {
  yield takeEvery(
    "FETCH_PARTNER_SUBAPPLICATIONS_TIMELINE",
    subApplicationTimeLine
  );
}

const subApplicationTimeLineSagas = [fork(watchSubApplicationTimeLine)];

export default subApplicationTimeLineSagas;
