import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morApplicationsList(data) {
  try {
    const result = yield call(
      allEndPoints.morApplicationsList,
      data.querry
    );
    yield put(actions.recieveMorApplicationsList({ ...result.data }));
  } catch (error) {
    yield put(actions.failMorApplicationsList);
  }
}

function* watchMorApplicationsList() {
  yield takeEvery("FETCH_MOR_APPLICATIONS_LIST",morApplicationsList);
}

const morApplicationsListSagas = [fork(watchMorApplicationsList)];

export default morApplicationsListSagas;