import { Field, ErrorMessage } from "formik";
import React from "react";
import textError from "./textError";

function Currency(props) {
  const { name, options, ...rest } = props;
  return (
    <div className="form-control">
      <Field
        style={{ height: "30px", width: "89px" }}
        as="select"
        id={name}
        name={name}
        {...rest}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.key}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component={textError} />
    </div>
  );
}

export default Currency;
