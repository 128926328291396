import { useLocation, useNavigate } from "react-router-dom";

const useDrawerHook = () => {
    
   const location = useLocation();
   const navigate = useNavigate();
   let search = ''

   const handleDrawerUrl = (type,querry='') =>{
        if(location.search.length > 0){
            if(type==='show'){
                search=`${location.search}&${querry}`
            }else{
                let splitSearch = location.search.split('&')
                console.log(splitSearch)
                splitSearch.splice(splitSearch.indexOf(querry),1)
                console.log('cghfjfh', search)
                search = splitSearch.join('')
            }
        }else{
            if(type==='show'){
                search=`?${querry}`
            }
       }
        if(type==='show'){
            navigate({
                pathname:'',
                search:search
            })
        }else if(type==='close'){
            navigate({
                pathname:'',
                search:search
            })
        }
   }
   
      return [ handleDrawerUrl ]
}

export default useDrawerHook