import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
  Flex,
  Tabs,
  TabPanel,
  TabPanels,
  Tab,
  TabList,
  Icon,
  TabIndicator,
} from "@chakra-ui/react";
import { Info, SubApplication, PartnerInfo } from "../components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../state";
import { allEndPoints } from "../../../services";
import { SelectedElement } from "rsuite/esm/Picker";
import { select } from "redux-saga/effects";

const ApplicationDetails = ({ isOpen, applications, onClose }) => {
  const dispatch = useDispatch();
  const { fetchSubApplicationTimeLine } = bindActionCreators(actions, dispatch);
  const state = useSelector((state) => state);
  const [tabeIndex, setTabeIndex] = useState(0);
  const [timeLine, setTimeLine] = useState([]);
  const selectApps = useMemo(() => {
    let apps = [];
    if (
      state.partnerApplicationDetails.data &&
      state.partnerApplicationDetails.data.LostApplicaitons
    ) {
      apps = [...state.partnerApplicationDetails.data.LostApplicaitons];
      if (state.partnerApplicationDetails.data.WinningApplicationID) {
        apps.push(state.partnerApplicationDetails.data.WinningApplicationID);
      }
    }
    return apps;
  }, [state.partnerApplicationDetails]);

  useEffect(() => {
    if (
      state.partnerApplicationDetails.data &&
      state.partnerApplicationDetails.data.LostApplicaitons &&
      selectApps.length > 0
    ) {
      console.log(selectApps);
      allEndPoints.subApplicationTimeLine(selectApps[tabeIndex]).then((res) => {
        console.log(res.data.data);
        setTimeLine(res.data.data);
      });
    }
  }, [tabeIndex, state.partnerApplicationDetails.data]);

  useEffect(() => {
    console.log(selectApps);
  }, [selectApps]);

  const navigate = useNavigate();
  return (
    <>
      <Drawer
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent bg="#F9F9F9">
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid #818181"
            padding="8px"
            ml="18px"
            mr="38px"
          >
            <h5>Detailed View</h5>
            <DrawerCloseButton position="none" />
          </DrawerHeader>
          <DrawerBody>
            <Box>
              <Flex position=" fixed" gap="20px">
                <Info applicationInfo={applications} />
                <PartnerInfo partnerInfo={applications} />
              </Flex>
            </Box>

            <Box mt="10px">
              <Tabs mt="172px" variant="enclosed">
                <Box>
                  <Flex mb="30px">
                    <TabList position=" fixed">
                      {selectApps.map((subapplication, applicationIndex) => (
                        <>
                          <Tab
                            key={applicationIndex}
                            bg="white"
                            fontSize="13px"
                            borderBottomColor=" GrayText"
                            borderBottom="2px solid  GrayText"
                            font-weight="bolder"
                            onClick={(data) => setTabeIndex(applicationIndex)}
                          >
                            <Icon
                              viewBox="0 0 200 200"
                              mr="4px"
                              style={{
                                color:
                                  subapplication._status === "Rejected"
                                    ? "#ee0404"
                                    : "#23ca04",
                              }}
                            >
                              <path
                                fill="currentColor"
                                d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                              />
                            </Icon>
                            SOLUTION {applicationIndex + 1}
                          </Tab>
                        </>
                      ))}
                    </TabList>
                  </Flex>
                </Box>

                <TabPanels mt="30px">
                  {selectApps.map((applicationIndex) => {
                    return (
                      <TabPanel
                        key="applicationIndex"
                        padding="1px"
                        p={applicationIndex}
                      >
                        <SubApplication applicationStatusList={timeLine} />
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ApplicationDetails;
