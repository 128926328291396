import React, { useState, useEffect } from "react";
import {
    Box,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    Drawer,
    Td,
    Divider,
    Tag,
    Flex,
    Th, TableContainer, Table, Thead, Tr, Tbody, useToast, Text, Center
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../../state";
import {
    useSearchParams,
    useNavigate
} from "react-router-dom";

import { allEndPoints } from "../../../../services";
import { StatusCircle } from "./style";


const MyShowUrls = ({ open, onClose, urlList, id }) => {
    const state = useSelector((state) => state);
    const [updateUrl, setUpdateUrl] = useState();
    const [isResponse, setIsResponse] = useState(false);
    const [responseType, setResponseType] = useState('')
    const [searchParams] = useSearchParams();
    const [isLoad, setIsLoad] = useState(false);
    const [selectedUrl, setSelectedUrl] = useState([]);
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();
    const { fetchMorUrlReq } = bindActionCreators(
        actions,
        dispatch
    );

    useEffect(() => {
        if (searchParams.get('mor_id')) {
            fetchMorUrlReq(searchParams.get('mor_id'));
        }
        console.log(searchParams.get('mor_id'))
    }, [searchParams]);

    useEffect(() => {
        if (state.morUrlReq.data) {
            setUpdateUrl(state.morUrlReq.data)
        }
    }, [state])


    // useEffect(() => {
    //     fetchMorShowUrlReq()
    // }, [])

    useEffect(() => {
        console.log(state)
    }, [state])


    if (!open) return null;


    return (

        <Drawer isOpen={open} placement="right" onClose={onClose} size="lg"   >

            <DrawerOverlay />
            <DrawerContent style={{
                overflow: "scroll",
                overflowX: "hidden",
                height: "auto",
                flexDirection: "column",
                justifyContent: "flex-start",
            }}>


                <DrawerHeader
                    display="flex"
                    justifyContent="space-between"
                    borderBottom="1px solid #818181"
                    padding="8px"
                >
                    <Text fontSize="28px" fontWeight="bold"> Urls</Text>
                    <>{state.morUrlReq.data && state.morUrlReq.data.data && state.morUrlReq.data.data.urls[0]}</>
                    <DrawerCloseButton position="none" />
                </DrawerHeader>

                <Box p={4}>


                    <TableContainer >
                        <Table variant="simple"  >
                            <Thead>
                                <Tr >
                                    <Th>Sector</Th>
                                    <Th>Url</Th>
                                    <Th>Status</Th>
                                </Tr>
                            </Thead>

                            <Tbody>
                                {  updateUrl && updateUrl.urls &&
                                    updateUrl.urls.length < 1 ? (
                                    <Center w="100%" h="100px">
                                        <h4 style={{ color: 'red', textAlign: 'center' }}>"No records found"</h4>
                                    </Center>
                                ) : (
                                    updateUrl && updateUrl.urls && updateUrl.urls.map((d) => {
                                        return (
                                            <Tr key={d['_id']}>

                                                <Td>{d.sector} </Td>
                                                <Td>{d.url}</Td>
                                                {/* <Td> <Flex alignItems={'center'}>
                                                    <Tag ml={2} colorScheme='green' >Approved </Tag>
                                                </Flex></Td> */}
                                                <Td>
                                                    <span style={{ display: "flex" }}>
                                                        <StatusCircle
                                                            style={{
                                                                background:
                                                                    d.status.title === "Approved"
                                                                        ? "#49D256"
                                                                        :
                                                                        d.status.title === "Declined"
                                                                            ? "#ce2828"
                                                                            : d.status.title === "Pending"
                                                                                ? "#FDBC14"
                                                                                : "#FDBC14",
                                                                marginRight: "10px",
                                                            }}
                                                            mr="14px"
                                                        />

                                                        {d.status.title || "N/A"}
                                                    </span>
                                                </Td>
                                            </Tr>
                                        );
                                    })
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>

                </Box>
            </DrawerContent>
        </Drawer>
    );
};
export default MyShowUrls;


