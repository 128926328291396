import { ems } from "../axios";

const api = (method, endPoint, data = "") => {
  let configuration = {
    method,
    url: endPoint,
    data,
  };
  return ems(configuration);
};

export const morApplicationsList = (q = "") => {
  // return api("GET", `mor-urls?limit=10&${q}`);
   return api("GET", `company-list?limit=10&${q}`);

};

//  MOR company-documents

export const morCompanyDocuments = (id) => {
  return api("GET", `company-documents/${id}`);
};

// MOR PARTNER COMPANY SOLUTION LIST
export const morPartnerSolList = (q = "") => {
  return api("GET", `partner-company-solutions?${q}`);
};


export const morSolList = (q = "") => {
  return api("GET", `solutions-list?${q}`);
};

//MOR MY URL REQUEST mor-urls LIST

export const morMyUrlRequest = (q = "") => {
  // return api("GET", `mor-urls?limit=10&${q}`);
   return api("GET", `mor-urls?limit=10&${q}`);

};


export const morUrlList = (id) => {
  console.log('id of url',id)
  return api("GET", `mor-request-url/${id}`);
};

//  MOR SHOW REQUEST URL
export const morShowUrlReq = (id) => {
  return api("GET", `mor-request-url/${id}`);
};

//  MOR NEW REQUEST URL
export const morUrlReq = (id) => {
  return api("GET", `mor-request-urls/${id}`);
};

// MOR DOCUMENT upload-attachment FOR SELECT SOLUTION
export const morDownloadAttachment =(data) =>{
  return api ("GET",`attachment?key=${data}`);
};

 
export const partnerApplicationsList = (q = "") => {
  return api("GET", `applications?${q}`);
};

export const partnerLogIn = (data) => {
  return api("POST", "login", data);
};

export const applicationForm = (data) => {
  return api("POST", "application", data);
};

export const morURLForm = (data) => {
  return api("POST", "mor-urls", data);
};

// MOR UPLOAD ATTACHMENT 
export const morUploadAttachment = (data) => {
  return api("POST", "upload-attachment", data);
};



export const applicationDetails = (id) => {
  return api("GET", `application/${id}`);
};

export const partnerApplicationCounts = () => {
  return api("GET", "application-count");
};

export const forgetPassword = (data) => {
  return api("POST", "forgot-password", data);
};

export const resetPassword = (data) => {
  return api("POST", "update-password", data);
};

export const removeMorUrls = (id,data) => {
  return api("POST", `add-remove-existing-url/${id}`, data);
};

export const subApplicationTimeLine = (data) => {
  return api(
    "GET",
    `application-timeline?deal_id=${data.deal_id}&sub_application_id=${data.sub_application_id}&parent_id=${data.parent_id}`
  );
};

export const loginPartnerInfo = () => {
  return api("GET", "me");
};

export default api;
