import React from "react";
import { FormLabel } from "@chakra-ui/react";

const FormLabels = ({ type }) => {
  return (
    <>
      <FormLabel
        htmlFor="username"
        position="absolute"
        top="-13px"
        left="17px"
        background="white"
        color="#436A6A"
        padding="1px"
        zIndex={1}
      >
        {type}
      </FormLabel>
    </>
  );
};

export default FormLabels;
