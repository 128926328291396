import React, { useState, useMemo } from "react";


import {
  Box,
  Heading,
  Divider,
  Text,
  FormControl,
  FormLabel,
  Button,
  useToast,
  Flex,
  Spacer,
  Input,
} from "@chakra-ui/react";
import "./approvalForm.css";
import Submit from "./submittedpopup";
// import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { allEndPoints } from "../../../../services";
import countryList from "react-select-country-list";
import * as Yup from "yup";
import FormikControl from "./formikControl";
import Multiselect from "multiselect-react-dropdown";

const ApprovalForm = () => {
  const [submitPopup, setSubmitPopup] = useState(false);

  // const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [inputList, setInputList] = useState([]);
  const toast = useToast();
  const [radioValue, setRadioValue] = useState("yes");
  const options = useMemo(() => countryList().getData(), []);

  const [applicationId, setAppllicationId] = useState("");
  const radioOptions = [
    { key: "Yes", value: "Yes" },
    { key: "No", value: "No" },
  ];

  const currencyOption = [
    { key: "USD", value: "USD" },
    { key: "EUR", value: "EUR" },
    { key: "GBP", value: "GBP" },
  ];

  const targettedRegion = [
    {
      cat: " Asia",
      key: " Asia",
    },
    {
      cat: "Europe",
      key: "Europe",
    },
    {
      cat: "Africa",
      key: "Africa",
    },
    {
      cat: "CIS",
      key: "CIS",
    },
    {
      cat: "North America",
      key: "North America",
    },
    {
      cat: "South America",
      key: "South America",
    },
    {
      cat: "Australasia",
      key: "Australasia",
    },
    {
      cat: "South Asia",
      key: "South Asia",
    },
    {
      cat: "Eurasia",
      key: "Eurasia",
    },
  ];

  const initialValues = {
    company_name: "",
    website: "",
    incorporation_country: "",
    operation_country: "",
    business_desc: "",
    business_type: 'No',
    process_profile: "",
    comment: "",
    targetted_region: [],
    website_cridential: "",
    currency_code: "GBP",
    startup:'No',
    trading_history:'No',
  };

  const region = (event) => {
    let regionArr = [];

    event.map((data) => {
      return regionArr.push(data.cat);
    });
    initialValues.targetted_region = regionArr;
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required("Required"),
    website: Yup.string().required("Required"),
    business_desc: Yup.string().required("Required"),
    process_profile: Yup.string().required("Required"),
    incorporation_country: Yup.string().required("Required"),
    operation_country: Yup.string().required("Required"),
    business_type: Yup.string().required("Required"),
    comment: Yup.string().required("Required"),
    // currency_code: Yup.string().required("Required"),
  });

  const onSubmit = (values, onSubmitProps) => {
    console.log(values);
    onSubmitProps.resetForm();
    values.business_type = radioValue;
    setIsLoad((prev) => !prev);
    toast({
      status: "warning",
      title: "Your application is submitting",
      position: "top-right",
    });

    allEndPoints
      .applicationForm(values)
      .then((res) => {
        if ((res.status = 200)) {
          let appObj = res.data.data;

          setAppllicationId(appObj.details.id);
          setIsLoad((prev) => !prev);

          setSubmitPopup(true);
        }
      })
      .catch((err) => {
        setIsLoad((prev) => !prev);
        Object.entries(err.response.data.errors).forEach(
          ([errorKey, errorVal], errorIndex) => {
            if (Object.keys(initialValues).includes(errorKey)) {
              toast({
                status: "error",
                title: errorVal[0],
                position: "top-right",
              });
            }
          }
        );
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <Box mt="20px" mr="15px" ml="15px" mb="15px">
              <Heading as="h5" size="sm" pt="2%">
                New merchant / pre-approval form
              </Heading>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <FormLabel fontSize="13px" w="294px" htmlFor="company_name">
                  Registered Company Name
                </FormLabel>

                <FormikControl
                  control="input"
                  type="text"
                  lable="company_name"
                  name="company_name"
                />
              </FormControl>

              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <Box w="305px">
                  <FormLabel m="0" fontSize="13px" htmlFor="website">
                    Website
                  </FormLabel>
                  <Text fontSize="9px">Please provide ALL website URL’s</Text>
                </Box>

                <FormikControl
                  control="input"
                  type="text"
                  lable="website"
                  name="website"
                />

                {/* <MorAddUrl /> */}
              </FormControl>

              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex">
                <Box w="305px">
                  <FormLabel m="0" fontSize="13px" htmlFor="text">
                    Website Credential
                  </FormLabel>
                  <Text fontSize="9px">
                    username and password if applicable
                  </Text>
                </Box>

                <FormikControl
                  control="input"
                  type="text"
                  lable="website_cridential"
                  name="website_cridential"
                />
              </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex">
                <FormLabel
                  fontSize="13px"
                  w="294px"
                  p="5px"
                  htmlFor="name"
                  name="incorporation_country"
                >
                  Country of Incorporation
                </FormLabel>

                <FormikControl
                  control="select"
                  lable="incorporation_country"
                  name="incorporation_country"
                  options={options}
                />
              </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <FormLabel
                  fontSize="13px"
                  w="294px"
                  p="5px"
                  htmlFor="first-name"
                  name="operation_country"
                >
                  Country of Operation / Trading Office
                </FormLabel>

                <FormikControl
                  control="select"
                  lable="operation_country"
                  name="operation_country"
                  options={options}
                />
              </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex">
              <FormLabel fontSize="13px" w="294px" p="5px" htmlFor="first-name">
                Targeted Region
              </FormLabel>
              <Multiselect
                displayValue="key"
                className="form-control "
                onKeyPressFn={function noRefCheck() {}}
                onRemove={(selectedItem) => {
                  const selectedValues = formik.values.targetted_region.filter(
                    (item) => item !== selectedItem.cat
                  );
                  formik.setFieldValue("targetted_region", selectedValues);
                }}
                onSelect={(selectedList) => {
                  formik.setFieldValue(
                    "targetted_region",
                    selectedList.map((item) => item.cat)
                  );
                }}
                options={targettedRegion}
              />
            </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <Box w="305px">
                  <FormLabel fontSize="13px" w="294px" htmlFor="business_desc">
                    Trading sector
                  </FormLabel>
                  <Text w="250px" fontSize="9px">
                    Type of Business
                  </Text>
                </Box>

                <FormikControl
                  control="input"
                  type="text"
                  lable="business_desc"
                  name="business_desc"
                />
              </FormControl>

              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <FormLabel
                  fontSize="13px"
                  w="294px"
                  htmlFor="processingprofile"
                >
                  Current processing volume
                </FormLabel>
                <Box w="350px">
                  <Text fontSize="11px" mb="8px">
                    Expected Volume (within first 3 months)
                  </Text>
                  <Flex>
                    <FormikControl
                      className="space from"
                      control="currency"
                      lable="currency_code"
                      name="currency_code"
                      options={currencyOption}
                    />

                    <FormikControl
                      style={{
                        height: "30px",
                        width: "400px",
                        padding: "4px",
                      }}
                      control="input"
                      type="number"
                      lable="process_profile"
                      name="process_profile"
                    />
                  </Flex>
                </Box>
              </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <Box w="262px">
                  <FormLabel fontSize="13px" w="305px" htmlFor="first-name">
                    Is the business regulated or licensed ?
                  </FormLabel>
                </Box>

                <FormikControl
                  control="radio"
                  name="business_type"
                  options={radioOptions}
                />
              </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <Box w="262px">
                  <FormLabel fontSize="13px" w="305px" htmlFor="first-name">
                    Is this a start up company? 
                  </FormLabel>
                </Box>

                <FormikControl
                  control="radio"
                  name="startup"
                  options={radioOptions}
                  
                />
              </FormControl>
              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <Box w="262px">
                  <FormLabel fontSize="13px" w="305px" htmlFor="first-name">
                    Has trading history ?
                  </FormLabel>
                </Box>

                <FormikControl
                  control="radio"
                  name="trading_history"
                  options={radioOptions}
                />
              </FormControl>

              <Divider border="1px solid #DEDEDE" mt="15px" mb="15px" />
              <FormControl display="inline-flex" isRequired>
                <FormLabel fontSize="13px" w="294px" htmlFor="comment">
                  Comment, Request or Notes:
                </FormLabel>

                <FormikControl
                  control="textarea"
                  label="comment"
                  name="comment"
                />
              </FormControl>
            </Box>
            <FormControl display="inline-flex">
              <FormLabel
                fontSize="13px"
                w="294px"
                htmlFor="first-name"
              ></FormLabel>
              <Button
                size="sm"
                bg="#436A6A"
                color="white"
                width="125px"
                variant="solid"
                fontSize="12px"
                type="submit"
                mt="29px"
                mb="29px"
                ml="15px"
                colorScheme="teal"
                isDisabled={!formik.isValid }
                isLoading={isLoad || formik.isSubmitting}
              >
                Submit
              </Button>
              <Submit
                open={submitPopup}
                onClose={() => setSubmitPopup(false)}
                application={applicationId}
              />
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ApprovalForm;
