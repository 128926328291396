import React, { useEffect } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../../state";

const Header = () => {
  const dispatch = useDispatch();
  const { fetchLoginPartnerInfo } = bindActionCreators(actions, dispatch);

  const state = useSelector((state) => state);

  useEffect(() => {
    fetchLoginPartnerInfo();
  }, []);

  return (
    <Box bg="#FFFFFF" w="100%" height="15%" p={4} color="black">
      <Heading>
        Welcome,{" "}
        {state.loginPartnerInfo.data && state.loginPartnerInfo.data.name}
      </Heading>
    </Box>
  );
};

export default Header;
