import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morUrlList(data) {
  try {
    const result = yield call(
      allEndPoints.morUrlList,
      // data.querry
    );
    yield put(actions.recieveMorUrlList({ ...result.data }));
  } catch (error) {
    yield put(actions.failMorUrlList);
  }
}

function* watchMorUrlList() {
  yield takeEvery("FETCH_MOR_URL_LIST",morUrlList);
}

const morUrlListSagas = [fork(watchMorUrlList)];

export default morUrlListSagas;