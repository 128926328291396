const createReducer = (state, type, payload, types) => {
  switch (type) {
    case `${types[0]}`:
      return { ...state, loading: true, data: payload };
    case `${types[1]}`:
      return { ...state, loading: false, data: payload };
    case `${types[2]}`:
      return { ...state, loading: false, data: payload };
    default:
      return { ...state };
  }
};

// PARTNER APPLICATIONS LIST REDUCER
const palInitialState = {
  loading: false,
  data: null,
};

export const partnerApplicationsList = (
  state = palInitialState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_PARTNER_APPLICATIONS_LIST",
    "RECIEVE_PARTNER_APPLICATIONS_LIST",
    "FAIL_PARTNER_APPLICATIONS_LIST",
  ]);
};



// MOR APPLICATION LIST REDUCER

const morInitialState = {
  loading: false,
  data: null,
};

export const morApplicationsList = (
  state = morInitialState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_APPLICATIONS_LIST",
    "RECIEVE_MOR_APPLICATIONS_LIST",
    "FAIL_MOR_APPLICATIONS_LIST",
  ]);
};
//  MOR APPLICATION LIST REDUCER

const morUrlListState = {
  loading: false,
  data: null,
};

export const morUrlList = (
  state = morUrlListState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_URL_LIST",
    "RECIEVE_MOR_URL_LIST",
    "FAIL_MOR_AURL_LIST",
  ]);
};
// MOR APPLICATION LIST REDUCER

const InitialState = {
  loading: false,
  data: null,
};

export const morSolList = (
  state = InitialState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_SOL_LIST",
    "RECIEVE_MOR_SOL_LIST",
    "FAIL_MOR_SOL_LIST",
  ]);
};

// MOR MY URL REQUEST REDUCER

const morMyUrlRequestState = {
  loading: false,
  data: null,
};

export const morMyUrlRequest = (
  state = morMyUrlRequestState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_MY_URL_REQUEST",
    "RECIEVE_MOR_MY_URL_REQUEST",
    "FAIL_MOR_MY_URL_REQUEST",
  ]);
};

// MOR DOWNLOAD ATTACHMENT

const morDownloadAttachmentState = {
  loading: false,
  data: null,
};


export const morDownloadAttachment = (
  state = morDownloadAttachmentState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_DOWNLOAD_ATTACHMENT",
    "RECIEVE_MOR_DOWNLOAD_ATTACHMENT",
    "FAIL_MOR_DOWNLOAD_ATTACHMENT",
  ]);
};

// MOR COMAPANY-DOCUMENTS

const morCompanyDocumentsState = {
  loading: false,
  data: null,
};


export const morCompanyDocuments = (
  state = morCompanyDocumentsState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_COMPANY_DOCUMENTS",
    "RECIEVE_MOR_COMPANY_DOCUMENTS",
    "FAIL_MOR_COMPANY_DOCUMENTS",
  ]);
};


// MOR PARTNER COMPANY SOLUTION

const morPartnerSolListInitialState = {
  loading: false,
  data: null,
};

export const morPartnerSolList = (
  state = morPartnerSolListInitialState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_PARTNER_COMPANY_SOLUTION",
    "RECIEVE_MOR_PARTNER_COMPANY_SOLUTION",
    "FAIL_MOR_PARTNER_COMPANY_SOLUTION",
  ]);
};


// MOR SHOW URL REQUEST
export const morShowUrlReq = (
  state = InitialState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_SHOW_URL_REQ",
    "RECIEVE_MOR_SHOW_URL_REQ",
    "FAIL_MOR_SHOW_URL_REQ",
  ]);
};

// MOR  URL REQUEST
const morUrlReqInitialState = {
  loading: false,
  data: null,
};


export const morUrlReq = (
  state =morUrlReqInitialState,
  { type, payload }
) => {
  return createReducer(state, type, {...payload}, [
    "FETCH_MOR_URL_REQ",
    "RECIEVE_MOR_URL_REQ",
    "FAIL_MOR_URL_REQ",
  ]);
};


// PARTNER APPLICATION DETAILS REDUCER
const padInitialState = {
  loading: false,
  data: null,
};

export const partnerApplicationDetails = (
  state = padInitialState,
  { type, payload }
) => {
  return createReducer(state, type, { ...payload }, [
    "FETCH_PARTNER_APPLICATION_DETAILS",
    "RECIEVE_PARTNER_APPLICATION_DETAILS",
    "FAIL_PARTNER_APPLICATION_DETAILS",
  ]);
};

// PARTNER APPLICATION COUNTS
const couInitialState = {
  loading: false,
  data: [],
};

export const partnerApplicationCounts = (
  state = couInitialState,
  { type, payload }
) => {
  return createReducer(state, type, payload, [
    "FETCH_PARTNER_APPLICATION-COUNTS",
    "RECIEVE_PARTNER_APPLICATION_COUNTS",
    "FAIL_PARTNER_APPLICATION_COUNTS",
  ]);
};

//  fetchSubApplicationTimeLine
const fetTimeLine = {
  loading: false,
  data: [],
};

export const subApplicationTimeLine = (
  state = fetTimeLine,
  { type, payload }
) => {
  return createReducer(state, type, payload, [
    "FETCH_PARTNER_SUBAPPLICATIONS_TIMELINE",
    "RECIEVE_PARTNER_SUBAPPLICATIONS_TIMELINE",
    "FAIL_PARTNER_SUBAPPLICATIONS_TIMELINE",
  ]);
};

////  loginPartnerInfo
const fetloginPartnerInfo = {
  loading: false,
  data: {},
};

export const loginPartnerInfo = (
  state = fetloginPartnerInfo,
  { type, payload }
) => {
  return createReducer(state, type, payload, [
    "FETCH_LOGIN_PARTNER_INFO",
    "RECIEVE_LOGIN_PARTNER_INFO",
    "FAIL_LOGIN_PARTNER_INFO",
  ]);
};
