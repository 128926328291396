import React from "react";
import { Navigate } from "react-router-dom";
import { ApplicationList, Resources, Signin, MyUrlRequests } from "../layouts";
import { Dashboard } from "../layouts/defaultLayouts/components";
import { ApprovalForm } from "../layouts/defaultLayouts/components";
import { MorApprovalForm } from "../layouts/morApplicationList/components";
import { ResetPassword } from "../layouts/resetpassword";

const routes = [


  // {
  //   path: "/index.html",
  //   element:
  //     localStorage.getItem("partner_type")=='MOR' ?  <Navigate replace  to='/my-trading-account-list'/> : <Navigate replace  to='/dashboard'/> 
  //   ,
  // },

  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/",
    element: <Signin />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/application/list",
    element: <ApplicationList />,
  },

  {
    path: "/my-trading-account-list",
    element: <MorApprovalForm />,
  },
  {
    path: "/approval/form",
    element: <ApprovalForm />,
  },
  {
    path: "/url/requests",
    element: <MyUrlRequests />
  },

  {
    path: "/reset-password/:forget_id",
    element: <ResetPassword />,
  },
  {
    path: "/resources",
    element: <Resources />,
  },
];

export default routes;
