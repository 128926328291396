import React from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const MainLayout = ({ children, isCollapse }) => {
  const location = useLocation();
  return (
    <Box
      mt={["/", "/signin"].includes(location.pathname) ? "" : "50px"}
      bg="#F5F5F5"
      w="100%"
      height="100%"
      pl={
        isCollapse && !["/", "/signin"].includes(location.pathname)
          ? "230px"
          : ""
      }
    >
      {children}
    </Box>
  );
};

export default MainLayout;
