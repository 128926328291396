import React from "react";
import { Flex, Box, Center, Button } from "@chakra-ui/react";
import { Icons } from "../../../../utils";
import { useNavigate } from "react-router-dom";

const Topbar = ({ isCollapse, onCollapse }) => {
  const navigate = useNavigate();
  const returnToDashboard = () => {
    let path = "/signin";
    localStorage.clear();
    navigate(path);
  };
  return (
    <Flex
      w="100%"
      borderBottom="2px solid var(--text-border-color)"
      bg="var(--text-white)"
      height="50px"
      position="fixed"
      top={0}
      left={0}
      zIndex={5}
      justifyContent="flex-end"
    >
   
      <Box mr="5" mt="1">
        <Button onClick={returnToDashboard}>Sign Out</Button>
      </Box>
    </Flex>
  );
};

export default Topbar;
