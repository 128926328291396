import React from "react";
import { List } from "../components";


export default function ApplicationList() {
  const columesHeadings = [
    "Date",
    "Age Days",
    "Business Name",
    "EMS Status",
    "Action",
  ];
  // const data = [
  //   {
  //     deal_Id: "AP-00001",
  //     date: "Apr 28, 2022",
  //     business_name: "Apollo",
  //     status: "Document Verification",
  //   },
  //   {
  //     deal_Id: "AP-00002",
  //     date: "Apr 28, 2022",
  //     business_name: "",
  //     status: "Approved",
  //   },
  //   {
  //     deal_Id: "AP-00003",
  //     date: "Apr 28, 2022",
  //     business_name: "",
  //     status: "Rejected",
  //   },
  // ];
  return (
    <>
      <List columes={columesHeadings} tableData />
    </>
  );
}
