import React from "react";

import { BsFillFunnelFill } from "react-icons/bs";

const Filter = () => {
    return(
       <BsFillFunnelFill />
    )
}

export default Filter;