import React from "react";
import { Box, Heading, Text, Flex, Container, Spacer } from "@chakra-ui/react";

const Info = ({ applicationInfo }) => {
  return (
    <>
      <Container bg="#FFFFFF" w="1418px" whiteSpace="10px">
        <div>
          <Flex mt="31px">
            <Box>
              <Text color="#818181" fontSize="xs">
                Comapny Name
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo && applicationInfo.companyName}
              </Heading>
            </Box>
            <Spacer />

            <Box>
              <Text color="#818181" fontSize="xs">
                Application
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo.appNumber}
              </Heading>
            </Box>
            <Spacer />

            <Box>
              <Text color="#818181" fontSize="xs">
                Submission Date
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo.date}
              </Heading>
            </Box>
            <Spacer />
            <Box>
              <Text color="#818181" fontSize="xs">
                Age Days
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo.age_days}
              </Heading>
            </Box>
          </Flex>
          <Flex mt="21px" mb="31px">
            <Box>
              <Text color="#818181" fontSize="xs">
                Sector
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo.sector}
              </Heading>
            </Box>
            <Spacer />

            <Box>
              <Text color="#818181" fontSize="xs">
                Trading License
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo.teadingLicense}
              </Heading>
            </Box>
            <Spacer />

            <Box>
              <Text color="#818181" fontSize="xs">
                Trading / Start up
              </Text>
              <Heading as="h3" size="sm">
                {applicationInfo.status}
              </Heading>
            </Box>
          </Flex>
        </div>
      </Container>
    </>
  );
};

export default Info;
