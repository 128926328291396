import React from "react";
import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
  Stack,
  Select,
  DrawerFooter,
  Button,
  Grid,
  Text,
  Input, FormControl, FormLabel, useToast, FormErrorMessage
} from "@chakra-ui/react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actions } from "./../../../state";
import { useState } from "react";
import { useEffect } from "react";
import { allEndPoints } from "../../../services";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { fetchMorUrlList } from "../../../state/actions/actions";
import { Message } from "rsuite";

const MorAddUrl = ({ open, onClose, solutionName, companyId }) => {
  const [inputList, setInputList] = useState([{}]);
  const [urlsList, setUrlsList] = useState([]);
  const [formData, setFormData] = useState({
    company_name: '',
    solutions: '',
    urls: [{ url: '', sector: '' }]
  });
  const [isResponse, setIsResponse] = useState(false);
  const [responseType, setResponseType] = useState('')
  const [error, setIsError] = useState([''])
  const resource = fetchMorUrlList();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoad, setIsLoad] = useState(false);
  const toast = useToast();
  const [solOptions, setSolOption] = useState([]);
  const state = useSelector((state) => state);
  const sample = []


  const handleInputChange = (e, index) => {
    console.log("formdata", e, index);
    const { name, value } = e.target;
    setUrlsList([...urlsList, {
      index: index,
      name: name,
      value: value
    }]);

    const list = [...inputList,
    ];
    console.log(list)
    list[index][{}] = name;
    list[index][{}] = value;

    console.log(list)
    setInputList(list);
  };
  useEffect(() => {
    console.log("url-list....", urlsList)
  }, [urlsList]);

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { urls: "", sector: "" }]);
  };

  const initialValues = {
    company_name: "",
    solutions: "",
  };

  const region = (event) => {
    let regionArr = [];
    event.map((data) => {
      return regionArr.push(data.cat);
    });
    initialValues.targetted_region = regionArr;
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required("Required"),
    sector: Yup.string().required("Required"),
    solutions: Yup.string().required("Required"),
  });

  useEffect(() => {
    console.log(solutionName)
    setFormData((prev) => {
      prev = { ...prev }
      prev.company_name = solutionName.name
      prev.solutions = solutionName.solution && solutionName.solution
      return prev
    })
  }, [solutionName]);

  const onSubmit = () => {
    setIsLoad(true);
    console.log(formData);

    //   Object.entries(urlsList).map(([key,value],formDataIndex)=>{
    // })
    //   var value = {
    //   company_name:formData?.company_name,
    //   sector:formData?.sector,
    //   solutions:formData?.solutions,
    //   urls:formData?.urls,
    //  }

    allEndPoints
      .morURLForm({ "companyId": companyId, "requestType": 1, urls: formData.urls })
      .then((res) => {
        console.log(res);
        setIsLoad(false)
        toast({
          status: "success",
          title: "Your add request has been submitted.",
          position: "top",
        });
        onClose();
        setIsResponse(true)
        setResponseType('success')
        setTimeout(() => {
          setIsResponse(false)
        }, 3000)

        setFormData({
          company_name: '',
          solutions: '',
          urls: [{ url: '', sector: '' }]
        })
        //  setFormData ({ 
        //  urls: [{ url: '', sector: '' }]})
        navigate({
          pathname: "",
          search: createSearchParams({
            'mor_id': searchParams.get()
          }).toString(),
        });
      })

      .catch((err) => {
        setIsLoad(false);
        // console.log(err);
      });
  };

  const handleFormData = (event) => {
    setFormData((prev) => {
      prev = { ...prev };
      let urls = [...prev.urls]
      if (event.target.name === 'urls') {
        urls.push({ url: '', sector: '' })
        prev.urls = urls
      } else if (event.target.name === 'urls-remove') {
        urls.splice(event.target.value, 1)
        prev.urls = urls
      } else if (event.target.name === 'url' || event.target.name === 'sector') {

        // const expression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
        const expression = /^([a-zA-Z0-9._-])+\.([a-zA-Z0-9._-])+$/;

        const regex = new RegExp(expression);
        if (event.target.name === 'url') {
          if (!event.target.value.match(regex)) {
            setIsError((prev) => {
              prev = [...prev]
              if (!prev.includes(event.target.index)) {

                prev.push(event.target.index)
              }
              return prev
            })
          } else {
            setIsError((prev) => {
              prev = [...prev]
              if (prev.includes(event.target.index)) {
                prev.splice(prev.indexOf(event.target.index), 1)
              }
              return prev
            })
          }
        }

        urls[event.target.index] = {
          ...event.target.name === 'url' ? {
            ...urls[event.target.index],
            url: event.target.value
          } : { ...urls[event.target.index], sector: event.target.value }
        }
        prev.urls = urls
      } else {
        prev[event.target.name] = event.target.value
      }
      return prev;
    });
 
  };

 


  useEffect(() => {
    setFormData((prev) => {
      prev = { ...prev }
      prev.urls = [{ url: '', sector: '' }]
      return prev
    })
  }, [onClose]);

  if (!open) return null;


  return (

    <Drawer isOpen={open} placement="right" onClose={onClose} size="sm">

      <DrawerOverlay />

      <DrawerContent overflow='scroll' overflowX="hidden" height={"auto"}>

        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #818181"
          padding="8px"
        >
          <h5>Add Request</h5>
          <DrawerCloseButton position="none" />
        </DrawerHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >

          <DrawerBody mt="0px">

            <Stack spacing="24px">

              <Box>
                <Box>
                  <FormControl isRequired>
                    <Box position="relative" zIndex={1} marginTop="20px">
                      <FormLabel>Company Name</FormLabel>
                      <Input
                        disabled
                        required
                        style={{ width: "400px" }}
                        name="company_name"
                        onChange={handleFormData}
                        value={formData.company_name}
                      ></Input>
                    </Box>
                  </FormControl>
                </Box>

                <FormControl isRequired>
                  <Box>
                    <FormLabel marginTop="17px">Solutions</FormLabel>
                    <Input
                      disabled
                      required
                      placeholder="Solutions"
                      style={{ width: "400px" }}
                      name="solutions"
                      value={formData.solutions}
                      onChange={handleFormData}
                    >
                      {/* {state.morSolList.data &&
                      state.morSolList.data.total < 1 ? (
                      <h2>"No records found"</h2>
                    ) : (
                      state.morSolList.data &&
                      state.morSolList.data.data &&
                      state.morSolList.data.data.map((d) => {
                        return (
                          <option value={d.solution_code}>
                            {d.solution_code}
                          </option>
                        );
                      })
                    )} */}
                    </Input>
                  </Box>
                </FormControl>
              </Box>

              <Box>
                <FormControl isRequired>
                  <FormLabel>Please provide website urls & sectors</FormLabel>
                  <Text style={{
                    fontSize: "11px",
                    marginBottom: "11px",
                    marginTop: "-7px"
                  }}  >
                    Please give me url like this(www.abc.com / abc.com).</Text>
                </FormControl>

                <div className="btn" >
                  {formData.urls.map((x, i) => {
                    return (
                      <div className="box"
                        control={initialValues}
                      >
                        <FormControl isRequired isInvalid={ x.url &&error.includes(i)}>
                          <span >
                          {  x.url && error.includes(i) ? (
                              <FormErrorMessage>Invalid Url.</FormErrorMessage>
                            ) : (
                              " "
                            )}
                            <Input
                              placeholder="Enter urls"
                              required
                              style={{ width: "180px" }}
                              name="url"
                              h="35px"

                              // type="url" 
                              // pattern="https?://.+"
                              onChange={(event) => handleFormData({ target: { name: 'url', value: event.target.value, index: i } })}
                              value={x.url}
                              // onChange={(e) => handleInputChange(e, i)}
                              // _focus={{
                              //   border: 'none'
                              // }}
                              // borderColor={error.includes(i) ? 'red.400' : 'initial'}
                            />
                           
                          </span>
                          <span >
                            <Input
                              placeholder="Enter sector"
                              required
                              style={{
                                width: "180px", marginBlockEnd: "8px", left: "190px",
                                marginTop: "-35px"
                              }}
                              name="sector"
                              h="35px"
                              onChange={(event) => handleFormData({ target: { name: 'sector', value: event.target.value, index: i } })}
                              // onChange={handleFormData}
                              value={x.sector}
                            // onChange={(e) => handleInputChange(e, i)}
                            />
                          </span>
                        </FormControl>

                        <div className="btn-box">
                          {formData.urls.length !== 1 && (
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "150px",
                              }}
                            >
                              <Button
                                size="sm"
                                bg="#E53E3E"
                                color="white"
                                // width="28px"
                                variant="solid"
                                fontSize="12px"
                                mb="20px"
                                ml="226px"
                                borderRadius={"5px"}
                                p='5px'
                                marginTop="-57px"
                                minW='5px' h='15px'
                                onClick={() => handleFormData({ target: { name: 'urls-remove', value: i } })}
                              >
                                -
                              </Button>
                            </span>
                          )}

                        </div>
                        <div className="btn-box">
                          {formData.urls.length - 1 === i && (
                            <span
                            >
                              <Button
                                size="sm"
                                bg="#436A6A"
                                color="white"
                                width="60px"
                                variant="solid"
                                fontSize="12px"
                                left="160px"
                                onClick={() => handleFormData({ target: { name: 'urls', value: formData.urls.length + 1 } })}
                              >
                                Add
                              </Button>
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

              </Box>

            </Stack>
          </DrawerBody>

          <Grid>
            <Button
              ml="22px"
              mr="22px"
              bg="#436A6A"
              color="white"
              type="submit"
              colorScheme="teal"
              isLoading={isLoad}
              mt="30px"

              _hover={{
                color: "#FFFFFF",
                backgroundColor: "#436A6A",
              }}
              _focus={{
                color: "#FFFFFF",
                backgroundColor: "#436A6A",
              }}
              disabled={error.length > 1}

            >
              Submit
            </Button>
          </Grid>
        </form>

        <DrawerFooter borderTopWidth="1px"></DrawerFooter>

      </DrawerContent>

    </Drawer>

  );
};

export default MorAddUrl;



