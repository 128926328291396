import React from "react";
import { Input as I, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { Icons } from "../../../../utils";

const Input = ({ type, placeholder, ...props }) => {
  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={
          !type && type !== "password" ? (
            <Icons.user
              fill="var(--table-heading)"
              width="1.2rem"
              height="1.2rem"
            />
          ) : (
            <Icons.key
              fill="var(--table-heading)"
              width="1.2rem"
              height="1.2rem"
            />
          )
        }
      />
      <I
        type={type ? type : "text"}
        placeholder={placeholder ? placeholder : "UserName"}
        border="none"
        style={{textIndent:'38px'}}
        outline="none"
        borderBottom="2px solid black"
        borderRadius={0}
        _hover={{
          borderBottom: "2px solid black",
        }}
        _focus={{
          outline: "none !important",
          border: "none !important ",
        }}
        _placeholder={{
          color: "black",
        }}
        {...props}
      />
    </InputGroup>
  );
};

export default Input;
