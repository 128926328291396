import React from "react";
import { Field, ErrorMessage } from "formik";
import textError from "./textError";

function Input(props) {
  const { label, name, ...rest } = props;
  return (
    <div className="form-control">
      <lable htmlFor={name}>{label}</lable>
      <Field
        style={{ height: "30px",paddingLeft: "5px" ,padding:"5px", marginleft: "44px" }}
        id={name}
        name={name}
        {...rest}
      />
      <ErrorMessage name={name} component={textError} />
    </div>
  );
}

export default Input;
