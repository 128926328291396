import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morMyUrlRequest(data) {
  try {
    const result = yield call(
      allEndPoints.morMyUrlRequest,
      data.querry
    );
    yield put(actions.recieveMorMyUrlRequest({ ...result.data }));
  } catch (error) {
    yield put(actions.failMorMyUrlRequest);
  }
}

function* watchMorMyUrlRequest() {
  yield takeEvery("FETCH_MOR_MY_URL_REQUEST",morMyUrlRequest);
}

const morMyUrlRequestSagas = [fork(watchMorMyUrlRequest)];

export default morMyUrlRequestSagas;