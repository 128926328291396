import React, { useEffect } from "react";
import { Box, Flex, Image, Avatar, Text } from "@chakra-ui/react";
import BrandLogo from "../../../../assets/brandlogo.png";
import SideLink from "./sideLink";
import LinkContainer from "./linkContainer";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../../state";
import resourceIcon from "../../../../assets/resources1.png";
import applicationIcon from "../../../../assets/application1.png";
import dashboardIcon from "../../../../assets/dashboards.png";
import merchantIcon from "../../../../assets/merchant.png";
import linkIcon from "../../../../assets/link1.png";
import requestIcon from "../../../../assets/request.png";
import { useState } from "react";

const Sidebar = () => {
  const location = useLocation();
  const [isFirstTimeRendered, setIsFirstTimeRendered] = useState(false);
  const [userInfo, setIsUserInfo] = useState(null)
  const dispatch = useDispatch();
  const { fetchLoginPartnerInfo } = bindActionCreators(actions, dispatch);

  const state = useSelector((state) => state);

  useEffect(() => {
    fetchLoginPartnerInfo();
  }, []);

  useEffect(() => {
    if (state.loginPartnerInfo?.data && Object.keys(state.loginPartnerInfo.data).length > 0 && !isFirstTimeRendered) {
      setIsUserInfo(state.loginPartnerInfo?.data)
      setIsFirstTimeRendered(true);
    }
  }, [state])

  useEffect(() => {
    console.log(userInfo)
  }, [userInfo])

  function NewTab() {
    window.open("https://support.ems-ltd.global", "_blank");
  }

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="230px"
      border="1px solid black"
      height="100vh"
      bg="var(--side-panel-background)"
      zIndex={7}
    >
      <Flex w="100%" p={4} justifyContent="center">
        <Image src={BrandLogo} w="85%" />
      </Flex>
      <Box pl={3} pr={3} pt={6}>
        <>
          <Flex w="100%" justifyContent="center">
            <Avatar
              size="xl"
              name={
                userInfo && userInfo.name
              }
              src=""
            />
          </Flex>
          <Text
            fontSize="20px"
            color="var(--text-white)"
            textAlign="center"
            mt={3}
            fontWeight={700}
          >
            {userInfo && userInfo.name}
          </Text>
          <Text
            fontSize="14px"
            color="var(--text-secondary)"
            textAlign="center"
            fontWeight={400}
          >
            {userInfo && userInfo.email}
          </Text>
        </>

        <LinkContainer
          links={[
            <>
              {userInfo && userInfo.type !== 'MOR' &&
                <SideLink
                  label="Dashboard"
                  link="/dashboard"
                  icon={<Image src={dashboardIcon} w="16px" h="16px" />}
                  isActive={location.pathname === "/dashboard"}
                />}
            </>,

            <>
              {userInfo && userInfo.type !== 'MOR' &&
                <SideLink
                  label="New Merchant Application"
                  link="/approval/form"
                  icon={<Image src={merchantIcon} w="16px" h="16px" />}
                  isActive={location.pathname === "/approval/form"}
                />}
            </>,

            <>
              {userInfo && userInfo.type !== 'MOR' &&
                <SideLink
                  label="My Applications"
                  link="/application/list?page=1&limit=10"
                  icon={<Image src={applicationIcon} w="18px" h="18px" />}
                  isActive={location.pathname === "/application/list"}
                />}
            </>,

            <>
              {userInfo && userInfo.type == 'MOR' &&
                <SideLink
                  label="My Companies"
                  link="/my-trading-account-list"
                  icon={<Image src={linkIcon} w="16px" h="16px" />}
                  isActive={location.pathname === "/my-trading-account-list"}
                />}
            </>,

            <>
              {userInfo && userInfo?.type == 'MOR' &&
                <SideLink
                  label="My Url Requests"
                  link="/url/requests"
                  icon={<Image src={requestIcon} w="18px" h="18px" />}
                  isActive={location.pathname === "/url/requests"}
                />}
            </>,

            <SideLink
              label="Resources"
              link="/resources"
              icon={<Image src={resourceIcon} w="18px" h="18px" />}
              isActive={location.pathname === "/resources"}
            />,


          ]}
        />,

      </Box>
    </Box>
  );
};

export default Sidebar;
