import React from "react";
import {
  Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Drawer,
  Stack,
  Select,
  DrawerFooter,
  Button,
  Grid,
  Text,
  Input,
  FormControl, FormLabel,useToast, FormErrorMessage
} from "@chakra-ui/react";

import {
  useNavigate,
  useSearchParams,
  useLocation
} from "react-router-dom";

import { useEffect , useState} from "react";
import { allEndPoints } from "../../../services";
import * as Yup from "yup";
import { useSelector ,useDispatch } from "react-redux";

import { bindActionCreators } from "redux";
import { actions } from "../../../state";
import DocumentUpload from "./documentUpload";
import ApplicationWithDocument from "./appliactionWithDocument";

const MorAddUrl = ({ open, onClose, solutionList,onChange,name }) => {
  const [inputList, setInputList] = useState([{}]);
  const [urlsList, setUrlsList] = useState([]);
  const [formData, setFormData] = useState({
    company_name: '',
    solutions: '',
    urls: [{ url: '', sector: '' }]
  });
  const [ renderedUI, setRenderedUI] = useState(null)
  const dispatch = useDispatch();
  const { fetchMorApplicationsList } = bindActionCreators(
    actions,
    dispatch
  );
  const location = useLocation();
  const [ isResponse, setIsResponse] = useState(false);
  const [ responseType, setResponseType] = useState('')
  const [ error, setIsError ] = useState([])
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoad, setIsLoad] = useState(false);
  const [solOptions, setSolOption] = useState([]);
  const state = useSelector((state) => state);
  const [uploadFile,setUploadFile]=useState([]);
  const sample = [];
  const toast = useToast();
  const [ solution,setSolution ] = useState(null)
  const handleInputChange = (e, index) => {
    console.log("formdata", e, index);
    // setUrlsList((prev) => {
    //   prev = JSON.parse(JSON.stringify(prev));
    //   prev[`${e.target.name}`] = e.target.value;
    //   prev["index"] = index;
    //   return prev;
    // });
    const { name, value } = e.target;



    setUrlsList([...urlsList, {
      index: index,
      name: name,
      value: value
    }]);

    // setFormData((prev) => {
    //   prev = { ...prev };
    //   prev[e.target.name] = e.target.value;
    //   console.log(prev);
    //   return prev;
    // });
    // console.log(name, value)
    const list = [...inputList,
    ];
    console.log(list)
    list[index][{}] = name;
    list[index][{}] = value;
    console.log(list)
    setInputList(list);
  };

  useEffect(() => {
    console.log("url-list....", urlsList)
  }, [urlsList]);

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { urls: "", sector: "" }]);
  };

  const initialValues = {
    company_name: "",
    sector: "",
    urls: "",
    solutions: "",
  };

  const region = (event) => {
    let regionArr = [];
    event.map((data) => {
      return regionArr.push(data.cat);
    });
    initialValues.targetted_region = regionArr;
  };

  const validationSchema = Yup.object({
    company_name: Yup.string().required("Required"),
    sector: Yup.string().required("Required"),
    solutions: Yup.string().required("Required"),
  });

  useEffect(() => {
    console.log(solutionList);
    if (state.morSolList.data && state.morSolList.data.data) {
      let ops = state.morSolList.data.data;
      ops.map((d, currencyIndex) => {
        d.value = d.solution_code;
        d.label = d.solution_code;
      });
      setSolOption(ops);
    }
  }, [state]);

  const onSubmit = () => {
    setIsLoad(true);
    console.log(formData);
    allEndPoints
      .morURLForm(formData)
      .then((res) => {
        console.log(res);
        setIsLoad(false)
        toast({
          status: "success",
          title: "Your add company request has been submitted.",
          position: "top",
        });
        onClose();
        // window.location.reload();
    navigate(`${location.pathname}${location.search}`)
    fetchMorApplicationsList(location.search)
        setIsResponse(true)
        setResponseType('success')
        setTimeout(()=>{
          setIsResponse(false)
        },3000)
      })
      .catch((err) => {
        setIsLoad(false);
        console.log(err);
      });
  };




  const handleFormData = (event) => {
    setFormData((prev) => {
      prev = { ...prev };
      let urls = [...prev.urls]
      if (event.target.name === 'urls') {
        urls.push({ url: '', sector: '' })
        prev.urls = urls
      } else if (event.target.name === 'urls-remove') {
        urls.splice(event.target.value, 1)
        prev.urls = urls
      } else if (event.target.name === 'url' || event.target.name === 'sector') {
        // const expression = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
      const expression = /^([a-zA-Z0-9._-])+\.([a-zA-Z0-9._-])+$/;
        const regex = new RegExp(expression);
        if(event.target.name==='url'){
          if(!event.target.value.match(regex)){
            setIsError((prev)=>{
              prev=[...prev]
              if(!prev.includes(event.target.index)){
                prev.push(event.target.index)
              }
              return prev
            })
          }else{
            setIsError((prev)=>{
              prev=[...prev]
              if(prev.includes(event.target.index)){
                prev.splice(prev.indexOf(event.target.index),1)
              }
              return prev
            })
          }
        }
        urls[event.target.index] = {
          ...event.target.name === 'url' ? { ...urls[event.target.index],
             url: event.target.value } : { ...urls[event.target.index], sector: event.target.value }
        }
        prev.urls = urls
      }else if(event.target.name==='documents'){
        if(!prev[event.target.name]){
          prev[event.target.name] = []
        }
        if(event.target.file_id)
        {
          const fileIndex = prev[event.target.name].findIndex((data)=>data.key===event.target.file_id)
          prev[event.target.name].splice(fileIndex,1) 
        }else{
        prev[event.target.name] = [...prev[event.target.name],event.target.value]

        }
      }
      else {
        prev[event.target.name] = event.target.value
      }
      return prev;
    });
   
  };

  const getDocUI = () => {
    let code = -1
    if(state.morSolList && state.morSolList.data && state.morSolList.data.data){
      const sol = state.morSolList.data.data.filter((data)=>data.solution_code
      ===formData.solutions)
      if(sol.length > 0){
        console.log(sol[0].type.id)
        if(sol[0].type.id === '1'){
        code = 0
        }else if(sol[0].type.id === '2'){
          code = 1
        }
      }
    }
    console.log(code)
    return code
  }

  useEffect(()=>{
    console.log(formData)
  },[formData])
  if (!open) return null;


  return (
    < div className="scroll" style={{overflowY: "auto" , height:"auto" }}>
    <Drawer isOpen={open} placement="right" onClose={onClose} size="md">

      <DrawerOverlay />
      <DrawerContent  overflow='scroll' overflowX="hidden" height="auto" 
       >
        <DrawerHeader
          display="flex"
          justifyContent="space-between"
          borderBottom="1px solid #818181"
          padding="8px"
        >
          <h5>Add Company</h5>
          <DrawerCloseButton position="none" />
        </DrawerHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <DrawerBody mt="0px">
            <Stack spacing="24px">
              <Box>

                <FormControl isRequired>
                  <Box position="relative" zIndex={1} marginTop="18px">
                    <FormLabel>Company Name</FormLabel>
                    <Input
                      required
                      style={{ width: "433px" }}
                      name="company_name"
                      onChange={handleFormData}
                      value={formData.company_name}
                    />
                  </Box>
                </FormControl>
              </Box>

              <FormControl isRequired>
                <Box marginTop="8px">
                  <FormLabel>Solutions</FormLabel>
                  <Select
                    required
                    variant="filled"
                    placeholder="Solutions"
                    size="md"
                    name="solutions"
                    value={formData.solutions}
                    onChange={(event)=>{
                      handleFormData(event)
                      setSolution(event.target.value)
                    }}
                  >
                    {state.morSolList.data &&
                      state.morSolList.data.total < 1 ? (
                      <h2>"No records found"</h2>
                    ) : (
                      state.morSolList.data &&
                      state.morSolList.data.data &&
                      state.morSolList.data.data.map((d) => {
                        return (
                          <option value={d.solution_code}>
                            {d.name}
                            {/* {d.type.id} */}
                          </option>
                        );
                      })
                    )}
                  </Select>
                </Box>
              </FormControl>
              {(getDocUI()=== 0 || getDocUI() === 1) && <DocumentUpload name="application" onClose={()=>{}} label={'Application'}
              onChange={handleFormData} extensions={['pdf','doc','docx','jpg','jpeg']}/>}
              {getDocUI()=== 1 && state.morSolList && state.morSolList.data && state.morSolList.data.data.map((sol,solIndex)=>(
                sol.type.id==='2' && sol.solution_code === solution && sol.documents.map((doc,docIndex)=>(
                  <DocumentUpload 
                    name={'documents'}
                    label={doc.name}
                    onChange={handleFormData}
                    isObject={true}
                    objectKeyName={doc.code}
                    onClose={handleFormData}
                    extensions={['pdf','doc','docx','jpg','jpeg']}
                  />
                ))
              ))}
       
              <Box>
                <FormControl isRequired >
                  <FormLabel>Please provide website urls & sectors</FormLabel>
                  <Text style={{
                    fontSize: "11px",
                    marginBottom: "11px",
                    marginTop: "-9px"
                  }}  >
                    Please give me url like this(www.abc.com / abc.com).</Text>
                </FormControl>

                <div className="btn"    >
                  {formData.urls.map((x, i) => {
                    return (
                      <div className="box"
                        control={initialValues}
                      >
                        <FormControl isRequired isInvalid={ x.url &&error.includes(i)}>
                          <span>
                          {  x.url && error.includes(i) ? (
                              <FormErrorMessage>Invalid Url.</FormErrorMessage>
                            ) : (
                              " "
                            )}
                            <Input
                              placeholder="Enter urls"
                              required
                              style={{ width: "210px" }}
                              name="url"
                              h="35px"
                              
                              onChange={(event) => handleFormData({ target: { name: 'url', value: event.target.value, index: i } })}
                              value={x.url}
                              // _focus={{
                              //   border:'none'
                              // }}
                              // borderColor={error.includes(i)?'red.400':'initial'}
                            // onChange={(e) => handleInputChange(e, i)}
                            />
                          </span>

                          <span
                          >
                            <Input
                              placeholder="Enter sector"
                              required
                              style={{
                                width: "210px", marginBlockEnd: "8px", left: "215px",
                                marginTop: "-35px"
                              }}
                              name="sector"
                              h="35px"
                              onChange={(event) => handleFormData({ target: { name: 'sector', value: event.target.value, index: i } })}
                              // onChange={handleFormData}
                              value={x.sector}
                            // onChange={(e) => handleInputChange(e, i)}
                            />
                          </span>
                        </FormControl>

                        <div className="btn-box">
                          {formData.urls.length !== 1 && (
                            <span
                              style={{
                                position: "absolute",
                                marginLeft: "150px",
                              }}
                            >
                              <Button
                                size="sm"
                                bg="#E53E3E"
                                color="white"
                                // width="28px"
                                variant="solid"
                                fontSize="12px"
                                mb="20px"
                                ml="286px"
                                borderRadius={"5px"}
                                p='5px'
                                marginTop="-57px"
                                minW='5px' h='15px'
                                onClick={() => handleFormData({target:{name:'urls-remove',value:i}})}
                              >
                                -
                              </Button>

                          
                            </span>
                          )}

                        </div>
                        <div className="btn-box">
                          {formData.urls.length - 1 === i && (
                            <span
                             
                            >
                              <Button
                                size="sm"
                                bg="#436A6A"
                                color="white"
                                width="60px"
                                variant="solid"
                                fontSize="12px"
                                left="186px"
                                onClick={() => handleFormData({ target: { name: 'urls', value: formData.urls.length + 1 } })}
                              >
                                Add
                              </Button>
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

              </Box>

            </Stack>
          </DrawerBody>

          <Grid>
            <Button
              ml="22px"
              mr="22px"
              bg="#436A6A"
              color="white"
              type="submit"
              colorScheme="teal"
              isLoading={isLoad}
              mt="30px"

              _hover={{
                color: "#FFFFFF",
                backgroundColor: "#436A6A",
              }}
              _focus={{
                color: "#FFFFFF",
                backgroundColor: "#436A6A",
                
              }}
              disabled={error.length > 0}
            >
              Submit
            </Button>
          </Grid>
        </form>

        <DrawerFooter borderTopWidth="1px"></DrawerFooter>
     
      </DrawerContent>
    
    </Drawer>
  
    </div>
  );
};

export default MorAddUrl;



