import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morShowUrlReq(data) {
  try {
    const result = yield call(

      allEndPoints.morShowUrlReq,
      data.id
    );
    console.log(result)
    
    yield put(actions.recieveMorShowUrlReq({ ...result.data}));
  } catch (error) {
    yield put(actions.failMorShowUrlReq);
  }
}

function* watchMorShowUrlReq() {
  yield takeEvery("FETCH_MOR_SHOW_URL_REQ",morShowUrlReq);
}

const morShowUrlReqSagas = [fork(watchMorShowUrlReq)];

export default morShowUrlReqSagas;