import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morDownloadAttachment (data) {
  try {
    const result = yield call(
      allEndPoints.morDownloadAttachment ,
      data.data
    );
    yield put(actions.recieveMorDownloadAttachment ({ ...result.data }));
  } catch (error) {
    yield put(actions.failMorDownloadAttachment);
  }
}

function* watchMorDownloadAttachment() {
  yield takeEvery("FETCH_MOR_DOWNLOAD_ATTACHMENT",morDownloadAttachment);
}

const morDownloadAttachmentSagas = [fork(watchMorDownloadAttachment)];

export default morDownloadAttachmentSagas;