import * as React from "react";

const Key = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    
    {...props}
  >
    <path d="M36 12.375c0 6.835-5.54 12.375-12.375 12.375-.789 0-1.56-.075-2.308-.216l-1.689 1.9a1.687 1.687 0 0 1-1.26.566H15.75v2.813c0 .931-.755 1.687-1.688 1.687H11.25v2.813c0 .931-.755 1.687-1.688 1.687H1.689A1.687 1.687 0 0 1 0 34.312v-5.488c0-.448.178-.877.494-1.193l11.377-11.377a12.364 12.364 0 0 1-.621-3.879C11.25 5.54 16.79 0 23.625 0A12.35 12.35 0 0 1 36 12.375ZM23.625 9a3.375 3.375 0 1 0 6.75 0 3.375 3.375 0 0 0-6.75 0Z" />
  </svg>
);

export default Key;
