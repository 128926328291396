import styled from "styled-components";

export const TableDiv = styled.div`
border-radius: 15px;
background:#fff;
.css-1zts0j{
    color:#436A6A,
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
line-height: 24px;
}
.row-id{
    color:#818181;
    width: fit-content;
    
}
}
.action{
    color:#4176FF;
    cursor:pointer;
    &:hover{
        text-decoration: underline;
    }
}
td{
    font-size: 14px;
    width: fit-content;
}

}
`;

export const StatusCircle = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-top: 6px;
`;
export const HedingDiv = styled.div`
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-size: 22px;
  }
  p {
    color: #818181;
    font-size: 13px;
  }
  button {
    background: #436a6a;
    color: white;
    &:hover {
      background: #436a6aad;
    }
  }
`;

export const PaginationDiv = styled.div`
  padding: 10px 24px;
`;
