import React from "react";
import { Flex, Center, Text ,Image} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Icons } from "../../../../utils";

const SideLink  = ({ icon, label, link, isActive, ...props }) => {
  return (
    <Link to={link ? link : ""} {...props}>
      <Flex
        p={2}
        _hover={{
          borderRadius: "7px",
          bg: "rgba(217, 217, 217, 0.18)",
        }}
        {...(isActive
          ? { borderRadius: "7px", bg: "rgba(217, 217, 217, 0.18)" }
          : {})}
      >
        <Center>
          {icon ? (
            icon
          ) : (
            <Icons.bento fill="var(--text-white)" width="1rem" hieght="1rem" />
            
          )}
        </Center>
        <Center ml={2}>
          <Text color="var(--text-white)" fontSize="14px" fontWeight={500}>
            {label ? label : "label"}
          </Text>
        </Center>
      </Flex>
    </Link>
  );
};

export default SideLink;
