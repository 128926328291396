// import React from 'react';
// import ConfirmAlert from 'react-confirm-alert'; 
// import {Button,Modal} from "@chakra-ui/react";

// const ModalAlert=({isOpen, onClose})=> {
//   const [showConfirm, setShowConfirm] = React.useState(false); 


//   const handleConfirm = () => {

//     setShowConfirm(false); 
//   };


//   const handleCancel = () => {
//     setShowConfirm(false); 
//   };

//   return (
//     <div>
//       <Button onClick={() => setShowConfirm(true)}>Remove</Button>
//  <Modal
//     width="100%"
//     isOpen={isOpen}
//     onClose={onClose}
//     alignItems="center"
//     direction="column"
//   >

//       {showConfirm && (
//         <ConfirmAlert
//           title="Confirm"
//           message="Are you sure you want to do this?"
//           confirmLabel="Yes"
//           cancelLabel="No"
//           onConfirm={handleConfirm}
//           onCancel={handleCancel}
//         />
//       )}

// </Modal>
//     </div>
//   );
// }

// export default ModalAlert;

import React, { useState, useRef } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Flex,
  ModalBody,
  ModalCloseButton, Button, useDisclosure, Text, Center
} from '@chakra-ui/react'
import { useEffect } from 'react'

const ModalAlert = ({ onConfirm, isDisabled ,isLoading, isResponse, responseType}) => {
  const { isOpen, onOpen, onClose, } = useDisclosure()
  
  const finalRef = useRef(null)

  return (
    <>

      <Flex justifyContent={'space-between'} mt={6} >
        <div></div>
        {isResponse && responseType==='success' && <Text color={'green.500'}>Your request has been submitted</Text>}
        {isResponse && responseType==='error' && <Text color={'red.500'}>Your request has not been submitted</Text>}
        <Button size="sm"
          bg="red"
          color="white"
          variant="solid"
          fontSize="12px"
          justifyContent="center"
          isDisabled={isDisabled}
          onClick={onOpen}
          isLoading={isLoading}
          >
          Deactivate
        </Button>
      </Flex>

      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent width={{md: "280px", base: "280px"}} height={{md: "180px", base: "180px"}}>

          <ModalCloseButton />

          <Center  >
            <Text marginTop={"70px"}>Do you want to delete these data?</Text>
          </Center>
          <ModalFooter>
            <Button size="sm"
               bg="#436A6A"
              color="white"
              width="60px"
              variant="solid"
              type="submit"
              left="-25px"
              onClick={() => {
                onConfirm()
                onClose()
              }}
            >
              Yes
            </Button>

            <span> <Button size="sm"
              bg="#436A6A"
              color="white"
              width="60px"
              variant="solid"
              fontSize="12px" onClick={() => {

                onClose();
              }}>
              No
            </Button></span>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default ModalAlert;