import React from "react";
import { Flex } from "@chakra-ui/react";
import { Header,List } from "../components";

const MyUrlRequests = () => {
    return(
        // <Flex 
        //     width="100%" 
        //     height="calc(100vh - 50px)"
        //     flexDirection="column"
        //     justifyContent="flex-start"
        //     alignItems="center"
        //     padding="1rem 1.5rem"
        // >
        //     {/* <Header /> */}
        //     <List 
        //         list={[
        //         [{
        //             label:'Id',
        //             value:'',
        //         },
        //         {
        //             label:'Company Name',
        //             value:'',
        //         },
        //         {
        //             label:'Date',
        //             value:'',
        //         },
        //         {
        //             label:'Solution',
        //             value:'',
        //         },
        //         {
        //             label:'Add/Remove Type',
        //             value:'',
        //         },
        //         {
        //             label:'Status',
        //             value:'',
        //         },
        //         {
        //             label:'Action',
        //             value:'',
        //         },
        //     ],
        //         // [{
        //         //     label:'Name',
        //         //     value:'Neha',
        //         // },
        //         // {
        //         //     label:'Surname',
        //         //     value:'Yadav',
        //         // }]
                
        //     ]}/>
        // </Flex>
        <>
        <List/>
        </>
    )
}

export default MyUrlRequests;



