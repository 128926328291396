import {
  Button,
  Center,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  Image,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import ThankYou from "../../../../assets/thankyou.png";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./styles.css";

const Submit = ({ open, onClose, application }) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const returnToDashboard = () => {
    let path = "/application/list";
    navigate(path);
  };

  if (!open) return null;
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay>
        <ModalContent left="53px" top="191px">
          <Flex pl="182px" pt="46px" bg="#436A6A">
            <Image src={ThankYou} w="84px" h="84px" />
          </Flex>
          <Center bg="#436A6A" h="50px">
            <Flex>
              <Heading color="white">Thank you!</Heading>
            </Flex>
          </Center>
          <Flex pl="62px" pb="54px" bg="#436A6A">
            <Text value={application} color="white">
              Application Id: {application}
            </Text>
            <CopyToClipboard text={application} onCopy={onCopyText}>
              <div className="copy-area">
                <Button
                  size="xs"
                  bg="white"
                  colorScheme="teal"
                  variant="outline"
                >
                  Copy ID
                </Button>
                {isCopied && (
                  <span className={`copy-feedback ${isCopied ? "active" : ""}`}>
                    Copied!
                  </span>
                )}
              </div>
            </CopyToClipboard>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Text mt="10px" fontWeight="600" fontSize="9px" mb="1rem">
              Your application has been submitted . It may take some time to
              process and list
            </Text>
          </ModalBody>

          <ModalFooter display="block">
            <Button
              size="sm"
              bg="#436A6A"
              color="white"
              width="180px"
              variant="solid"
              fontSize="12px"
              display="flex nun"
              colorScheme="blue"
              mr={3}
              onClick={returnToDashboard}
            >
              Return to Application List
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

export default Submit;
