import React from "react";

function Resources() {
  return (
    <div>
      <iframe
        style={{ width: "100%" }}
        src="https://workdrive.zohopublic.in/embed/gr9yc42e0cb58c30d490fa5b3334e0f4581c3?toolbar=false&layout=grid&appearance=light&themecolor=green"
        scrolling="no"
        frameborder="0"
        width="800"
        height="520"
      ></iframe>
    </div>
  );
}

export default Resources;
