import { Flex } from "@chakra-ui/react";
import { ErrorMessage, Field } from "formik";
import React from "react";
import textError from "./textError";

function RadioButtons(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div style={{ marginLeft: "44px" }}>
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            return (
              <React.Fragment key={option.key}>
                <Flex display="inline-flex">
                  <lable style={{ width: "30px" }} htmlFor={option.value}>
                    {option.key}
                  </lable>
                  <input
                    style={{ width: "auto", marginRight: "30px" }}
                    type="radio"
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                </Flex>
              </React.Fragment>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={textError} />
    </div>
  );
}

export default RadioButtons;
