import { takeEvery, call, fork, put } from "redux-saga/effects";
import actions from "../actions";
import { allEndPoints } from "../../services";

function* morUrlReq(data) {
  try {
    const result = yield call(

      allEndPoints.morUrlReq,
      data.id
    );
    console.log(result)
    
    yield put(actions.recieveMorUrlReq({ ...result.data}));
  } catch (error) {
    yield put(actions.failMorUrlReq);
  }
}

function* watchMorUrlReq() {
  yield takeEvery("FETCH_MOR_URL_REQ",morUrlReq);
}

const morUrlReqSagas = [fork(watchMorUrlReq)];

export default morUrlReqSagas;