import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Button,
  TableContainer,
  Center,
} from "@chakra-ui/react";
import { HedingDiv, PaginationDiv, StatusCircle, TableDiv } from "./style";
import GPagination from "./Pagination/GPagination";
import { Icons } from "../../../utils";
import { Header } from "../../defaultLayouts/components/header";
import { SearchFilter } from "../../defaultLayouts/components";
import { ApplicationDetails } from "../../applicationDetails";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../state";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import { FullLoader } from "../../../components";

export default function List({ columes, tableData, pending, approved }) {
  const dispatch = useDispatch();
  const { fetchPartnerApplicationsList, fetchPartnerApplicationDetails } =
    bindActionCreators(actions, dispatch);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = useSelector((state) => state);
  const [searchFilter, setSearchFilter] = useState(false);

  const getQuerries = (type = "str") => {
    let qrStr = "";
    let qrObj = {};
    let qpList = [
      "status",
      "deal_id",
      "page",
      "limit",
      "bussiness_name",
      "stage",
    ];
    qpList.forEach((qp) => {
      if (searchParams.get(qp)) {
        let val =
          qp === "page"
            ? parseInt(searchParams.get(qp)) - 1
            : searchParams.get(qp);
        qp = qp === "page" ? "offset" : qp;
        qrStr = qrStr + `${qp}=${val}&`;
        qrObj[qp] = searchParams.get(qp);
      }
    });
    if (type === "obj") {
      return qrObj;
    } else {
      return qrStr;
    }
  };

  useEffect(() => {
    if (searchParams.get("application_id") )
     {
      console.log(searchParams.get("application_id") )
      fetchPartnerApplicationDetails(searchParams.get("application_id"));
    } else {
      const qrStr = getQuerries();
      let applist = fetchPartnerApplicationsList(qrStr);
    }
  }, [searchParams]);

  return (
    <>
      <Header />
      <div style={{ padding: "4px" }}>
        <TableDiv>
          <HedingDiv>
            <span>
              <h2>Application List</h2>
            </span>
            <Button
              rightIcon={
                <Icons.filter
                  fill="var(--text-white)"
                  width="2rem"
                  hieght="2rem"
                />
              }
              size="sm"
              variant="solid"
              onClick={() => setSearchFilter(true)}
            >
              Filter
            </Button>
            <SearchFilter
              open={searchFilter}
              onClose={() => setSearchFilter(false)}
            />
          </HedingDiv>

          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  {columes.map((title) => {
                    return <Th>{title}</Th>;
                  })}
                </Tr>
              </Thead>
              {state.partnerApplicationsList.loading && <FullLoader />}
              <Tbody>
                {state.partnerApplicationsList.data &&
                state.partnerApplicationsList.data.total < 1 ? (
                  <Tr>
                    <Center w="321%" h="100px">
                      <h2>"No records found"</h2>
                    </Center>
                  </Tr>
                ) : (
                  state.partnerApplicationsList.data &&
                  state.partnerApplicationsList.data.data &&
                  state.partnerApplicationsList.data.data.map((d) => {
                    return (
                      <Tr>
                        <Td className="row-id">{d.date || "N/A"}</Td>
                        <Td>{d.age_days || "N/A"}</Td>
                        <Td>{d.bussiness_name || "N/A"}</Td>
                        <Td>
                          <span style={{ display: "flex" }}>
                            <StatusCircle
                              style={{
                                background:
                                  d.status === "Approved"
                                    ? "#49D256"
                                    : d.status === "Rejected"
                                    ? "#FF3232"
                                    : "#FDBC14",
                                marginRight: "10px",
                              }}
                              mr="14px"
                            />

                            {d.status || "N/A"}
                          </span>
                        </Td>
                        <Td
                          className="action"
                          onClick={() => {
                            navigate({
                              pathname: "",
                              search: createSearchParams({
                                application_id: d.id,
                              }).toString(),
                            });
                          }}
                        >
                          Show Details
                        </Td>
                      </Tr>
                    );
                  })
                )}
              </Tbody>

              <Tfoot>
                <PaginationDiv>
                  <GPagination
                    currentPage={
                      searchParams.get("page")
                        ? parseInt(searchParams.get("page"))
                        : 1
                    }
                    totalCount={
                      state.partnerApplicationsList.data
                        ? state.partnerApplicationsList.data.total
                        : 0
                    }
                    pageSize={
                      searchParams.get("limit") ? searchParams.get("limit") : 10
                    }
                    onPageChange={(page) => {
                      let qObj = { ...getQuerries("obj") };
                      delete qObj["page"];
                      navigate({
                        pathname: "",
                        search: createSearchParams({
                          page,
                          limit: 10,
                          ...getQuerries("obj"),
                        }).toString(),
                      });
                    }}
                  />
                </PaginationDiv>
              </Tfoot>
              
            </Table>
          </TableContainer>
        </TableDiv>
        {searchParams.get("application_id") && (
          <ApplicationDetails
            isOpen={searchParams.get("application_id")}
            onClose={() => {
              navigate(-1);
            }}
            applications={state.partnerApplicationDetails.data}
          />
        )}
      </div>
    </>
  );
}
