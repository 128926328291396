
import React, { useState, useEffect } from "react";
import {
    Table,
    Box,
    FormControl,
    Text,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    TableContainer,
    Divider,
    Tfoot,
    Select,
    Center,
    Input,
} from "@chakra-ui/react";
import {
    HedingDiv,
    PaginationDiv,
    SearchBtton,
    SearchFields,
    StatusCircle,
    TableDiv,
} from "./style";

// import { Icons } from "../../../utils";
// import { Header } from "../../defaultLayouts/components/header";
import { useDrawerHook } from "../../../../hooks";
import { ApplicationDetails } from "../../../applicationDetails";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actions } from "../../../../state";
import {
    useNavigate,
    createSearchParams,
    useSearchParams,
    useLocation,
} from "react-router-dom";
import { FullLoader } from "../../../../components";
import { allEndPoints } from "../../../../services";
import MyShowUrls from "./myShowUrls"
import GPagination from "./Pagination/GPagination";
import { fetchMorMyUrlRequest } from "../../../../state/actions/actions";

const List = ({ open, onClose }) => {
    const [formData, setFormData] = useState({
        page: 1,
        size: 10,
    });
    const [search, setSearch] = useState(false);
    const [isShowUrls, setShowUrls] = useState(false);
    const [isAddRequest, setAddRequest] = useState(false);
    const dispatch = useDispatch();
    const { fetchMorApplicationsList, fetchMorSolList, fetchLoginPartnerInfo, fetchMorMyUrlRequest, fetchMorPartnerSolList, fetchMorUrlReq } = bindActionCreators(
        actions,
        dispatch
    );
    const [ handleDrawerUrl ] = useDrawerHook()

    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const state = useSelector((state) => state);
    const [urlList, setUrlList] = useState([]);
    const [solutionName, setSolutionName] = useState([]);
    const [companyId, setCompanyId] = useState([]);

    const getDate = (date) => {
        let newDate = new Date(date)
        if(newDate){
            return date
        }
        return `${newDate.getFullYear()}-${newDate.getMonth() < 10 ? '0':''}${newDate.getMonth()}-${newDate.getDate() < 10 ?'0':''}${newDate.getDate()}`
    }

    const getQuerries = (type = "str") => {
        let qrStr = "";
        let qrObj = {};
        let qpList = ["page", "solution", "company", "status"];
        qpList.forEach((qp) => {
            if (searchParams.get(qp)) {
                let val =
                    qp === "page"
                        ? parseInt(searchParams.get(qp)) - 1
                        : searchParams.get(qp);
                qp = qp === "page" ? "offset" : qp;
                if (qp === "offset") { qrStr = qrStr + `${qp}=${val * 10}&`; } else {
                    qrStr = qrStr + `${qp}=${val}&`;

                }
                qrObj[qp] = searchParams.get(qp);

            }
        });
        console.log(qrObj)
        if (type === "obj") {
            return qrObj;
        } else {
            return qrStr;
        }
    };

    const handleFormData = (event) => {
        console.log("formdata", event.target.value);
        setFormData((prev) => {
            prev = { ...prev };
            prev[event.target.name] = event.target.value;
            return prev;
        });
    };


    useEffect(() => {
        fetchMorSolList();
    }, []);


    useEffect(() => {
        fetchMorMyUrlRequest();
    }, []);

    useEffect(() => {
        fetchMorPartnerSolList();
    }, []);

    useEffect(() => {
        console.log(location)
    }, [search])


    const SearchFilter = () => {
        console.log(formData);
        navigate({
            pathname: "",
            search: createSearchParams({
                ...formData,
                page: 1,
                limit: 10,
            }).toString(),
        });
    };

    useEffect(() => {
        fetchLoginPartnerInfo();
        const qrStr = getQuerries();
        if (!searchParams.get('mor_id') && !isShowUrls) {
            fetchMorMyUrlRequest(qrStr);
        }
    }, [searchParams]);
    // console.log(fetchMorApplicationsList)


    const handleShowUrl = (id) => {
        // {
        //     navigate({
        //         pathname: "",
        //         search: createSearchParams({
        //             'mor_id': id
        //         }).toString(),
        //     });
        // }
        handleDrawerUrl('show',`mor_id=${id}`)

    }



    return (
        <>
            <div style={{ background: "white" }}>
                <span>
                    <Box bg="#FFFFFF" w="50%" height="5%" p={4} color="black">
                        <Text
                            style={{ fontSize: "30px", fontWeight: "bold", marginLeft: "10px" }}
                        >
                            My Url Requests
                        </Text>
                    </Box>

                    <Divider />
                </span>


                <div marginTop={"-7px"}>
                    <Box marginBlockStart={"30px"} h="100%" w="98%" marginLeft="1%" >
                        <div style={{ padding: "4px" }}>
                            <TableDiv>
                                <HedingDiv>
                                    <SearchFields>
                                        <div className="filter-field">
                                            <span>
                                                <Select
                                                    variant="filled"
                                                    placeholder="Select Solutions"
                                                    size="md"
                                                    name="solution"
                                                    value={formData.solution}
                                                    onChange={handleFormData}
                                                >
                                                    {state.morPartnerSolList.data &&
                                                        state.morPartnerSolList.data.total < 1 ? (
                                                        <h2>"No records found"</h2>
                                                    ) : (
                                                        state.morPartnerSolList.data &&
                                                        state.morPartnerSolList.data.data &&
                                                        state.morPartnerSolList.data.data.map((d) => {
                                                            return (
                                                                <option value={d.code}>
                                                                    {d.name}
                                                                </option>
                                                            );
                                                        })
                                                    )}
                                                </Select>
                                            </span>

                                            <span>
                                                <Input placeholder='Company Name' size='md' 
                                                name="company" onChange={handleFormData}
                                                />
                                            </span>

                                            <span>
                                                <Select
                                                    variant="filled"
                                                    placeholder=" Select Status"
                                                    size="md"

                                                    value={formData.status}
                                                    name="status"
                                                    onChange={handleFormData}
                                                >
                                                    <option value="1">Processed</option>
                                                    <option value="0">Pending</option>
                                                </Select>
                                            </span>
                                        </div>
                                        <div className="search-btn">
                                            <Button size="md" variant="solid" onClick={SearchFilter}>

                                                Search
                                            </Button>

                                            <Button size="md" variant="solid" onClick={() => {
                                                setFormData('');

                                                navigate({
                                                    pathname: "",
                                                    search: createSearchParams({
                                                        page: 1,
                                                        limit: 10,
                                                    }).toString(),
                                                });
                                                window.location.reload();

                                            }}>
                                                Clear
                                            </Button>
                                        </div>
                                    </SearchFields>
                                </HedingDiv>

                                <TableContainer>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                {/* <Th>Id</Th> */}
                                                <Th>Date</Th>
                                                <Th>Company Name</Th>
                                                <Th>Solution</Th>
                                                <Th>Request Type</Th>
                                                <Th>Status</Th>
                                                <Th>Action</Th>
                                            </Tr>
                                        </Thead>
                                        {state.morMyUrlRequest
                                            .loading && (
                                                <FullLoader />
                                            )}
                                        <Tbody>
                                            {state.morMyUrlRequest.data &&   state.morMyUrlRequest.data.data &&
                                                state.morMyUrlRequest.data.data.length< 1 ? (
                                                <Tr>
                                                    <Center w="321%" h="100px">
                                                        <h2>"No records found"</h2>
                                                    </Center>
                                                </Tr>
                                            ) : (
                                                state.morMyUrlRequest.data &&
                                                state.morMyUrlRequest.data.data &&
                                                state.morMyUrlRequest.data.data.map((d) => {
                                                    return (
                                                        <Tr>
                                                            {/* <Td>{d.id}</Td> */}
                                                            <Td>{getDate(d.date)}</Td>
                                                            <Td>{d.company || "N/A"}</Td>

                                                            
                                                            <Td>{d.solution}</Td>
                                                            <Td>{d.requestType.title || "N/A"}</Td>
                                                            <Td>
                                                                <span style={{ display: "flex" }}>
                                                                    <StatusCircle
                                                                        style={{
                                                                            background:
                                                                                d.status.id == 1
                                                                                    ? "#49D256"

                                                                                    : d.status.id == 0
                                                                                        ? "#FDBC14"
                                                                                        : "#FDBC14",
                                                                            marginRight: "10px",
                                                                        }}
                                                                        mr="14px"
                                                                    />

                                                                    {d.status.title || "N/A"}

                                                                </span>
                                                            </Td>

                                                            <Td
                                                                className="action"
                                                                onClick={() => {
                                                                    setShowUrls(true)
                                                                    handleShowUrl(d.id)

                                                                }}
                                                            >
                                                                Show Urls

                                                            </Td>
                                                        </Tr>
                                                    );
                                                })
                                            )}
                                        </Tbody>

                                        <Tfoot>
                                            <PaginationDiv>
                                                <GPagination
                                                    currentPage={
                                                        searchParams.get("page")
                                                            ? parseInt(searchParams.get("page"))
                                                            : 1
                                                    }
                                                    totalCount={
                                                        state.morMyUrlRequest.data
                                                            ? state.morMyUrlRequest.data.FilterdRecordCount
                                                            : 0
                                                    }
                                                    pageSize={
                                                        searchParams.get("limit") ? searchParams.get("limit") : 10
                                                    }
                                                    onPageChange={(page) => {
                                                        console.log(page)
                                                        setShowUrls(false)
                                                        let qObj = { ...getQuerries("obj") };
                                                        delete qObj["page"];
                                                        navigate({
                                                            pathname: "",
                                                            search: createSearchParams({
                                                                page,
                                                                limit: 10,
                                                                ...getQuerries("obj"),
                                                            }).toString(),
                                                        });
                                                        console.log(state.morMyUrlRequest.data.TotalRecordCount)
                                                        console.log(page)
                                                    }}
                                                />
                                            </PaginationDiv>
                                        </Tfoot>
                                    </Table>
                                </TableContainer>
                            </TableDiv>
                            <MyShowUrls
                                open={searchParams.get('mor_id')}
                                onClose={() => {
                                    // navigate('/url/requests')
                                    handleDrawerUrl('close',`mor_id=${searchParams.get('mor_id')}`)

                                }
                                }
                                urlList={urlList}
                            />

                            {searchParams.get("application_id") && (
                                <ApplicationDetails
                                    isOpen={searchParams.get("application_id")}
                                    onClose={() => {
                                        navigate(-1);
                                    }}
                                    applications={state.partnerApplicationDetails.data}
                                />
                            )}
                        </div>
                    </Box>
                </div>
            </div>
        </>
    );
};
export default List;
