import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from './config';
import { DefaultLayouts } from '../layouts';
import { Middleware } from "../components";

const AppRoutes = () => {
  return (
    <DefaultLayouts>
      <Middleware>
        <Routes>
          {routes.map((route, routeIndex) => (
            <>
            <Route {...route} key={routeIndex} />
            </>
          ))}
        </Routes>
      </Middleware>
    </DefaultLayouts>
  );
};

export default AppRoutes;
